import axios from "axios";
import { BASE_URL } from "../Constants";
import { store } from '../../redux/store'
import RoutePath from "../routes/RoutePath";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
let encryptId = localStorage.getItem("vendorId")
const checkForRedirect = () => {
  const route = window.location.href.split('/')
  switch (route[3]) {
    case "admin":
      window.location.href = RoutePath.ADMIN_LOGIN_SCREEN_PATH
      break;
    case "vendor":
      window.location.href = RoutePath.VENDOR_LOGIN_SCREEN_PATH
      break;
    case "customer":
      window.location.href = `/custom/${encryptId && encryptId}`
    default:
      window.location.href = "/"
      break;
  }
  localStorage.clear();
}
export const publicHeaders = () => {
  return {
    Authorization: "Basic YWdlbnQ6YWdlbnQ=",
    "Content-Type": "application/json",
  };
};

export const forgetPasswordPublicHeaders = () => {
  const accessToken = store?.getState()?.LOGIN_CREDS?.publicToken?.access_token;
  const tokenType = store?.getState()?.LOGIN_CREDS?.publicToken?.token_type;
  return {
    Authorization: `${tokenType} ${accessToken}`,
    "Content-Type": "application/json",
  };
};

export const adminPublicHeaders = () => {
  return {
    "Authorization": "Basic c3VwZXJfYWRtaW46c3VwZXJfYWRtaW4=",
    "Content-Type": "application/json",
  };
};

export const vendorPublicHeaders = () => {
  return {
    "Authorization": "Basic dmVuZG9yX2FkbWluOnZlbmRvcl9hZG1pbg==",
    "Content-Type": "application/json",
  };
};
export const forgetPasswordHeader = () => {
  return {
    "Authorization": "Basic cHVibGljOnB1YmxpYw==",
    "Content-Type": "application/json",
  };
};

export const authorizationHeader = () => {
  const accessToken = store?.getState()?.LOGIN_CREDS?.tokenDetails?.access_token;
  const tokenType = store?.getState()?.LOGIN_CREDS?.tokenDetails?.token_type;
  return {
    "Authorization": `${tokenType} ${accessToken}`,
    "Content-Type": "application/json",
  }
}

export const adminAuthorizationHeader = () => {
  const accessToken = store?.getState()?.LOGIN_CREDS?.adminTokenDetails?.access_token;
  const tokenType = store?.getState()?.LOGIN_CREDS?.adminTokenDetails?.token_type;
  return {
    "Authorization": `${tokenType} ${accessToken}`,
    "Content-Type": "application/json",
  }
}

export const vendorAuthorizationHeader = () => {
  const accessToken = store?.getState()?.LOGIN_CREDS?.vendorTokenDetails?.access_token;
  const tokenType = store?.getState()?.LOGIN_CREDS?.vendorTokenDetails?.token_type;
  return {
    "Authorization": `${tokenType} ${accessToken}`,
    "Content-Type": "application/json",
  }
}
export const vendorFormDataAuthorizationHeader = () => {
  const accessToken = store?.getState()?.LOGIN_CREDS?.vendorTokenDetails?.access_token;
  const tokenType = store?.getState()?.LOGIN_CREDS?.vendorTokenDetails?.token_type;
  return {
    "Authorization": `${tokenType} ${accessToken}`,
    "Content-Type": "multipart/form-data",
  }
}
export const adminFormDataAuthorizationHeader = () => {
  const accessToken = store?.getState()?.LOGIN_CREDS?.adminTokenDetails?.access_token;
  const tokenType = store?.getState()?.LOGIN_CREDS?.adminTokenDetails?.token_type;
  return {
    "Authorization": `${tokenType} ${accessToken}`,
    "Content-Type": "multipart/form-data",
  }
}
export const doForgetPassword = async (url, data) => {
  const config = {
    headers: forgetPasswordHeader(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    if (response.data.response_code === 0) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doPost = async (url, data) => {
  const config = {
    headers: publicHeaders(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    if (response.data.response_code === 0) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};
export const doForgetPost = async (url, data) => {
  const config = {
    headers: forgetPasswordPublicHeaders(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    if (response.data.response_code === 0) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doLogin = async (url, data, isAdmin, isVendor) => {
  const config = {
    headers: isAdmin ? adminPublicHeaders() : isVendor ? vendorPublicHeaders() : publicHeaders(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    if (response.data.response_code === 0) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doGET = async (url, params) => {
  const config = {
    params,
    headers: publicHeaders(),
  };
  try {
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doAdminGET = async (url, params, isAdmin) => {
  const config = {
    params,
    headers: isAdmin ? adminAuthorizationHeader() : vendorAuthorizationHeader(),
  };
  try {
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doAdminPost = async (url, data, isAdmin) => {
  const config = {
    headers: isAdmin ? adminAuthorizationHeader() : vendorAuthorizationHeader(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const doAgentGET = async (url, params) => {
  const config = {
    params,
    headers: authorizationHeader(),
  };
  try {
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};
export const doAgentPost = async (url, data) => {
  const config = {
    headers: authorizationHeader(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.log("error", error);
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};


export const doVendorPost = async (url, data, isAdmin) => {
  const config = {
    headers: vendorAuthorizationHeader(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doVendorPostFormData = async (url, data, isAdmin) => {
  const config = {
    headers: vendorFormDataAuthorizationHeader(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doAdminPostFormData = async (url, data, isAdmin) => {
  const config = {
    headers: isAdmin ? adminFormDataAuthorizationHeader() : vendorFormDataAuthorizationHeader(),
  };
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};

export const doVendorGET = async (url, params) => {
  const config = {
    params,
    headers: vendorAuthorizationHeader(),
  };
  try {
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      return checkForRedirect();
    }
    throw error;
  }
};