import { doAction, doResponseAction } from ".";

const FEEDBACK = "FEEDBACK";
const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
const FEEDBACK_FAILURE = "FEEDBACK_FAILURE";
const ATTEND_CALL = "ATTEND_CALL";

export {
    FEEDBACK,
    FEEDBACK_SUCCESS,
    FEEDBACK_FAILURE,
    ATTEND_CALL
};

export const feedBackAction = (params, callback) => doAction(FEEDBACK, params, callback);
export const feedBackSuccessAction = (data) => doResponseAction(FEEDBACK_SUCCESS, data);
export const feedBackFailureAction = (data) => doResponseAction(FEEDBACK_FAILURE, data);

export const isCallAttendedAction = (data) => doResponseAction(ATTEND_CALL, data);
