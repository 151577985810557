import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import RoutePath from "../utils/routes/RoutePath";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import Actions from "../redux/actions";
import adminLogo from '../assets/img/Live_Agent_Logo.svg'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    paymentValiate = (e, vendor, paymentStatus) => {
        if (vendor === 'vendor' && paymentStatus == 0) {
            e.preventDefault()
        }
    }
    render() {
        const { getLoginDetails = {} } = this.props
        const { vendorTokenDetails = {}, isAR, paymentStatus, vendorLogo, argumentedReality } = getLoginDetails
        const route = this.props.match.path.split('/')
        return (
            <div className="card-left sidebar-content p-0 d-none d-md-block">
                <div className="logo-content">
                    <img src={route[1] === "admin" ? adminLogo : vendorLogo} className="img-fluid main-logo" alt="logo" />
                </div>
                <ul className="nav flex-column">
                    <Link to={route[1] === "admin" ? RoutePath.ADMIN_DASHBOARD_SCREEN : RoutePath.VENDOR_DASHBOARD_SCREEN} className={`nav-item link-item  ${route[2] === "dashboard" ? 'link-type' : ''}`}>
                        <li className="nav-link link-content ps-lg-5 py-3"><p className="m-0"><i className="fa-solid fa-gauge-high dashboard-icon"></i></p><p className="m-0">{Constants.DASHBOARD}</p></li>
                    </Link>
                    {route[1] === "admin" &&
                        <Link to={RoutePath.ADMIN_VENDOR_LIST} className={`nav-item link-item  ${route[2] === "vendor" ? 'link-type' : ''}`}>
                            <li className="nav-link link-content ps-lg-5 py-3"><p className="m-0"><i className="fa-solid fa-user-group vendor-icon"></i></p><p className="m-0">{Constants.VENDOR}</p></li>
                        </Link>}
                    <Link to={route[1] === "admin" ? RoutePath.ADMIN_AGENT_LIST : RoutePath.VENDOR_AGENT_LIST} className={`nav-item link-item ${route[2] === "agent" ? 'link-type' : ''}`} onClick={(e) => this.paymentValiate(e, route[1], paymentStatus)}>
                        <li className="nav-link link-content ps-lg-5 py-3 agentPaymentTooltip">
                            <p className="m-0 ">
                                <i className="fa-solid fa-users agent-icon">
                                </i>
                            </p>
                            <p className="m-0">{Constants.AGENT}
                                {(route[1] === "vendor" && paymentStatus == 0) && <Tooltip anchorSelect=".agentPaymentTooltip" place="top">
                                    Your subscription payment is not done
                                </Tooltip>}
                            </p>
                        </li>
                    </Link>
                    {route[1] === "admin"
                        && <Link to={RoutePath.SUBSCRIPTION_LIST} className={`nav-item link-item ${route[2] === "subscription" ? 'link-type' : ''}`}>
                            <li className="nav-link link-content ps-lg-5 py-3"><p className="m-0"><i className="fa-solid fa-bell bell-icon"></i></p><p className="m-0">{Constants.SUBSCRIPTION}</p></li>
                        </Link>}
                    <Link to={route[1] === "admin" ? RoutePath.ADDON_LIST : RoutePath.VENDOR_ADDON_LIST} className={`nav-item link-item ${route[2] === "addon" ? 'link-type' : ''}`} onClick={(e) => this.paymentValiate(e, route[1], paymentStatus)}>
                        <li className="nav-link link-content ps-lg-5 py-3 addonPaymentTooltip">
                            <p className="m-0">
                                <i className="fa-solid fa-circle-plus bell-icon">
                                </i>
                            </p>
                            <p className="m-0">{Constants.ADDON}
                                {(route[1] === "vendor" && paymentStatus == 0) && <Tooltip anchorSelect=".addonPaymentTooltip" place="top">
                                    Your subscription payment is not done
                                </Tooltip>}
                            </p>
                        </li>
                    </Link>
                    {(route[1] === "vendor") &&
                        <Link to={RoutePath.VENDOR_PROFILE_DETAILS} className={`nav-item link-item ${route[2] === "profile" ? 'link-type' : ''}`} >
                            <li className="nav-link link-content ps-lg-5 py-3">
                                <p className="m-0">
                                    <i className="fa-solid fa-user bell-icon">
                                    </i>
                                </p>
                                <p className="m-0">Profile
                                </p>
                            </li>
                        </Link>}
                    {(route[1] === "vendor") &&
                        <Link to={RoutePath.VENDOR_VB_LIST} className={`nav-item link-item ${route[2] === "virtualBackground" ? 'link-type' : ''}`} >
                            <li className="nav-link link-content ps-lg-5 py-3">
                                <p className="m-0">
                                    <i className="fa-solid fa-chalkboard bell-icon">
                                    </i>
                                </p>
                                <p className="m-0">Virtual Background
                                </p>
                            </li>
                        </Link>}
                    {(route[1] === "vendor" && argumentedReality == 1 && isAR == 1) &&
                        <Link to={RoutePath.VENDOR_AR_PRODUCTS} className={`nav-item link-item ${route[2] === "products" ? 'link-type' : ''}`} onClick={(e) => this.paymentValiate(e, route[1], paymentStatus)}>
                            <li className="nav-link link-content ps-lg-5 py-3 arProductPaymentTooltip">
                                <p className="m-0">
                                    <i className="fa-brands fa-unity bell-icon">
                                    </i>
                                </p>
                                <p className="m-0">AR Products
                                    {(route[1] === "vendor" && paymentStatus == 0) && <Tooltip anchorSelect=".arProductPaymentTooltip" place="top">
                                        Your subscription payment is not done
                                    </Tooltip>}
                                </p>
                            </li>
                        </Link>}
                    {route[1] === "admin" &&
                        <Link to={RoutePath.ADMIN_AR_PRODUCTS} className={`nav-item link-item ${route[2] === "products" ? 'link-type' : ''}`} >
                            <li className="nav-link link-content ps-lg-5 py-3">
                                <p className="m-0">
                                    <i className="fa-brands fa-unity bell-icon">
                                    </i>
                                </p>
                                <p className="m-0">AR Products
                                </p>
                            </li>
                        </Link>}
                    {/* {route[1] === "vendor" &&
                        <Link to={RoutePath.VENDOR_WEBHOOK_CONFIG} className={`nav-item link-item ${route[2] === "webhook" ? 'link-type' : ''}`}>
                            <li className="nav-link link-content ps-lg-5 py-3"><p className="m-0"><i className="fa-solid fa-gear bell-icon"></i></p><p className="m-0">Settings</p></li>
                        </Link>} */}
                </ul>
            </div>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getLoginDetails: Actions.getLoginDetails(state)
    }
};
export default withRouter(connect(mapStateToProps, {
})(SideBar));
