import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import Constants from "../utils/Constants";
import MultiSelectVendor from "../utils/MultiSelectVendor";
class AddAgentScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        }
    }
    render() {
        const { showPassword } = this.state
        const { isEdit, agentDetails, handleUserName, handleMobileNumber, handleDropdownChange, handleVendorChange, handleEmailChange, handlePasswordChange, submit, goBack, isAgent, getVendorList, handleSelectedAgents, handleRemovedAgents } = this.props
        const { error, message } = agentDetails;
        const { vendorActiveList } = getVendorList || []
        let selected = []
        let Arr = []
        vendorActiveList && vendorActiveList?.forEach(obj => {
            if (obj?.Vendor?.agentTypeId == 2) {
                if (obj?.Vendor?.VendorAgents?.length < obj?.Vendor?.Subscription?.agentLimit) {
                    return Arr.push(obj)
                } else {
                    selected.push(obj)
                }
            }
        })
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="">
                            <p className="text-bold">{Constants.NAME}</p>
                            <input autoComplete="off" maxLength="35" type="text" name='fullName' className="form-control w-50" value={agentDetails.fullName} onChange={(e) => handleUserName(e)} placeholder={Constants.ENTER_NAME} />
                            {error?.isFullName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isFullName}</span>}
                        </div>
                        <div className="mt-3">
                            <p className="text-bold">{Constants.EMAIL}</p>
                            <input autoComplete="off" type="text" name='email' className="form-control w-50" value={agentDetails.email} onChange={(e) => handleEmailChange(e)} placeholder={Constants.ENTER_EMAIL_ID} />
                            {error?.isEmailId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isEmailId}</span>}
                        </div>
                        {!isEdit && <><div className="mt-3">
                            <p className="text-bold">{Constants.PASSWORD}</p>
                            <div className="mt-4 input-group flex-nowrap mb-4 w-50">
                                <input autoComplete="off" type={showPassword ? 'text' : 'password'} name='password' className="form-control w-50" aria-label="Password" aria-describedby="view-pass" value={agentDetails.password} onChange={(e) => handlePasswordChange(e)} placeholder={Constants.ENTER_PASSWORD} />
                                <button className="btn"
                                    onClick={() => this.setState({ showPassword: !showPassword })}
                                    type="button"
                                    id="view-pass">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                            {error?.isPassword && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isPassword}</span>}
                        </div>
                        </>
                        }
                        <div className="mt-3">
                            <p className="text-bold">{Constants.MOBILE_NUMBER}</p>
                            <input autoComplete="off" type="number" name='phone' className="form-control w-50" value={agentDetails.phone} onChange={(e) => handleMobileNumber(e)} placeholder={Constants.ENTER_MOBILE_NUMBER} />
                            {error?.isMobileNumber && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isMobileNumber}</span>}
                        </div>
                        <div className="mt-3">
                            <p className="text-bold">{Constants.TYPE}</p>
                            <select name='agentType' disabled={isEdit} value={agentDetails.agentType} onChange={(e) => handleDropdownChange(e)} className="form-select w-50">
                                <option hidden>{Constants.SELECT_AGENT_TYPE}</option>
                                <option value="1">{Constants.SHARED}</option>
                                <option value="2">{Constants.OWNED}</option>
                            </select>
                            {error?.agentType && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.agentType}</span>}
                        </div>
                        {(agentDetails.agentType == 2 && isAgent) &&
                            <div className="mt-3">
                                <p className="text-bold">{Constants.SELECT_VENDOR} </p>
                                <select name='vendorId' value={agentDetails.vendorId} onChange={(e) => handleVendorChange(e)} className="form-select w-50">
                                    <option hidden>-- Select Vendor --</option>
                                    {selected?.map((o, i) => {
                                        return <>
                                            <option value={o?.id} key={i} hidden>{o?.fullName + " - " + o?.Vendor?.companyName}</option>
                                        </>
                                    })}
                                    {Arr?.map((o, index) => {
                                        return <>
                                            <option value={o?.id} key={index}>{o?.fullName + " - " + o?.Vendor?.companyName}</option>
                                        </>
                                    })
                                    }
                                </select>
                                {error?.vendorId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.vendorId}</span>}
                            </div>}
                        {(agentDetails.agentType == 1 && isAgent) &&
                            <div className="w-50 mt-3">
                                <label htmlFor="product-list" className="w-75 form-label fw-bold">Select Vendor</label>
                                <MultiSelectVendor
                                    id="assignedStore"
                                    style={{ margin: '10px' }}
                                    placeholder="Select Vendors"
                                    value={agentDetails.vendors.selectedAgents}
                                    onSelect={(val) => handleSelectedAgents(val)}
                                    onRemove={(val) => handleRemovedAgents(val)}
                                />
                                {error?.vendors && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.vendors}</span>}
                            </div>
                        }
                        <div className=" mt-3">
                            <button type="button" className="btn input-btn" onClick={() => goBack()}>{Constants.BACK}</button>
                            <button type="button" className="btn input-btn ms-2" onClick={() => submit()}>{isEdit ? Constants.UPDATE : Constants.SUBMIT}</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default AddAgentScreen
