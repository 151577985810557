import { CUSTOMER_LIST } from "../actions/CustomerListAction";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doPost } from "../../utils/service/index";
export function* customerListSaga({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.CUSTOMER_LIST_API, params);
    if (response) {
      yield put(Actions.customerListSuccessAction(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.customerListFailureAction(e));
    callback && callback(e);
  }
}

export default function* customerListSagaListener() {
  yield takeLatest(CUSTOMER_LIST, customerListSaga);
}
