import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import { Tooltip } from "react-tooltip";

class CallNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: ""
        }
    }
    render() {
        const { callDetails, joinNow, rejectNow, callMute, mute } = this.props;
        return (
            <div className='call-notification'>
                {
                    callDetails?.map((o, index) => {
                        return <React.Fragment key={index}>
                            <div className='card call-card'>
                                <p className='user-call-name'>
                                    <span className='name-high'>{o?.User?.fullName}</span>
                                    <span className='d-none d-md-block'>{Constants.IS_CALLING}</span>
                                </p>
                                <div className="d-flex justify-content-center icons mt-4">
                                    <div className="cuatomer-call-circle-end mx-3 OnJoinIcon attenCallTooltip" onClick={() => joinNow(o)}>
                                        <i className="fa-solid fa-phone mic-icon-end"></i>
                                        <Tooltip anchorSelect=".attenCallTooltip" place="top">
                                            Attend Call
                                        </Tooltip>
                                    </div>
                                    <div className="cuatomer-call-circle-end red-end mx-3 OnRejectIcon rejectCallTooltip" onClick={() => rejectNow(o)}>
                                        <i className="fa-solid fa-phone call-icon-end" ></i>
                                        <Tooltip anchorSelect=".rejectCallTooltip" place="top">
                                            Reject Call
                                        </Tooltip>
                                    </div>
                                    {mute ? <div className="cuatomer-call-circle-end mx-3 OnRejectIcon mutedTooltip" onClick={() => callMute()}>
                                        <i className="fa-solid fa-volume-xmark mic-icon-end" ></i>
                                        <Tooltip anchorSelect=".mutedTooltip" place="top">
                                            Muted
                                        </Tooltip>
                                    </div> : <div className="cuatomer-call-circle-end mx-3 OnRejectIcon muteTooltip" onClick={() => callMute()}>
                                        <i className="fa-solid fa-volume-high mic-icon-end" ></i>
                                        <Tooltip anchorSelect=".muteTooltip" place="top">
                                            Mute
                                        </Tooltip>
                                    </div>}
                                </div>
                            </div>
                        </React.Fragment>
                    })
                }
            </div>
        )
    }
}
export default withRouter(CallNotification);