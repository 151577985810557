import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddAgentScreen from '../component/AddAgentScreen';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import RoutePath from '../utils/routes/RoutePath';
import { isCharacterReg, isValidEmail, isValidPassword } from '../utils/validation';
import { toast } from 'react-toastify';
import AppLoader from '../utils/AppLoader';
import Constants from '../utils/Constants';


class AddAgent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fullName: '',
            email: '',
            password: '',
            phone: '',
            error: {},
            message: {},
            emptyFieldError: [],
            errorData: '',
            isVendor: false,
            agentType: null,
            vendorId: null,
            vendors: {}
        }
    }

    componentDidMount() {
        const { getAgentDetails = {} } = this.props
        const { vendorAddAgentDetails = {} } = getAgentDetails
        this.props.vendorList()
        if (this.props.match.params.id) {
            const callback = (response) => {
                if (response) {
                    const selectedAgent = response?.response?.vendorAgentDetails?.map(o => {
                        return {
                            label: o?.Vendor?.User?.fullName + " - (" + o?.Vendor?.companyName + ")",
                            id: o?.Vendor?.User?.id,
                            value: o?.Vendor?.User?.id,
                            data: o?.Vendor?.User,
                            vendorAgentId: o?.id
                        }
                    })
                    this.setState({
                        fullName: vendorAddAgentDetails?.fullName?.length > 0 ? vendorAddAgentDetails.fullName : response?.response?.fullName,
                        email: vendorAddAgentDetails?.email?.length > 0 ? vendorAddAgentDetails.email : response?.response?.email,
                        agentType: vendorAddAgentDetails?.agentType?.length > 0 ? vendorAddAgentDetails.agentType : response?.response?.agentType,
                        phone: vendorAddAgentDetails?.phone?.length > 0 ? vendorAddAgentDetails.phone : response?.response?.phone,
                        isVendor: this.props.location.pathname === ("/vendor/agent/edit/" + this.props.match.params.id),
                        vendorId: vendorAddAgentDetails?.phone?.length > 0 ? null : response?.response?.VendorAgent?.Vendor?.userId,
                        vendors: {
                            selectedAgents: selectedAgent
                        }
                    }, () => this.props.addAgentDetails({ ...this.state }))
                }
            }
            this.props.getAgent({ id: this.props.match.params.id }, callback, (this.props.location.pathname === ("/admin/agent/edit/" + this.props.match.params.id)))
        } else {
            this.setState({ isVendor: this.props.location.pathname === RoutePath.VENDOR_ADD_AGENT_LIST })
        }
    }

    addAgentDetails = () => {
        const { getAgentDetails = {}, getLoginDetails = {} } = this.props
        const { addAgentDetails = {}, vendorAddAgentDetails } = getAgentDetails
        const { adminTokenDetails = {}, vendorTokenDetails = {}, tokenDetails = {} } = getLoginDetails
        if (this.state.isVendor ? vendorAddAgentDetails?.agentType : addAgentDetails?.agentType === "") {
            this.props.addAgentDetails({ ...this.state })
        }
        const callback = (response) => {
            if (response?.response_code === 0) {
                toast.success(Constants.ADDED_SUCCESSFULLY)
                this.props.addAgentDetails(undefined)
                this.props.history.push(this.state.isVendor ? RoutePath.VENDOR_AGENT_LIST : RoutePath.ADMIN_AGENT_LIST)
            } else {
                toast.error(response?.error?.response?.data?.response_message)
            }
        }
        let addParams = {
            agentType: this.state.isVendor ? vendorAddAgentDetails?.agentType : addAgentDetails?.agentType,
            phone: this.state.isVendor ? vendorAddAgentDetails?.phone : addAgentDetails?.phone,
            email: this.state.isVendor ? vendorAddAgentDetails?.email : addAgentDetails?.email,
            fullName: this.state.isVendor ? vendorAddAgentDetails?.fullName : addAgentDetails?.fullName,
            password: this.state.isVendor ? vendorAddAgentDetails?.password : addAgentDetails?.password
        }
        if (addParams.agentType == 2 && this.state.vendorId) {
            addParams.vendorId = this.state.isVendor ? vendorTokenDetails?.userDetails?.id : addAgentDetails?.vendorId
        }
        if (addParams.agentType == 1 && this.state?.vendors?.selectedAgents?.length > 0) {
            if (!this.state.isVendor) {
                addParams.vendors = this.state.vendors
            }
        }
        if (!addParams.fullName) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.PLEASE_ENTER_THE_NAME } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!isCharacterReg(addParams.fullName)) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.NAME_REGX } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (addParams.fullName.trim().length < 3) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.NAME_LENGTH } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!addParams.email) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.PLEASE_ENTER_EMAIL } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!isValidEmail(addParams.email)) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.INVALID_EMAIL_ID } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!addParams.password || addParams.password.length === 0) {
            this.setState({ error: { isPassword: true }, message: { isPassword: Constants.PLEASE_ENTER_PASSWORD } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!isValidPassword(addParams.password)) {
            this.setState({ error: { isPassword: true }, message: { isPassword: Constants.PASSWORD_REG } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!addParams.phone) {
            this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: Constants.PLEASE_ENTER_THE_MOBLIE_NUMBER } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (addParams.phone.length < 10) {
            this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: Constants.MOBILE_NUMBER_MUST_BE_10_CHARCTER } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!addParams.agentType) {
            this.setState({ error: { agentType: true }, message: { agentType: Constants.PLEASE_SELECT_AGENT_TYPE } }, () => this.props.addAgentDetails({ ...this.state }))
        } else {
            this.props.addAgent(addParams, callback, (!this.state.isVendor))
            // if (addParams.agentType == 2) {
            //     if (!this.state.vendorId) {
            //         if (!addParams.vendorId) {
            //             this.setState({ error: { vendorId: true }, message: { vendorId: Constants.PLEASE_SELECT_VENDOR } }, () => this.props.addAgentDetails({ ...this.state }))
            //         } else {
            //             this.props.addAgent(addParams, callback, (!this.state.isVendor))
            //         }
            //     } else {
            //         this.props.addAgent(addParams, callback, (!this.state.isVendor))
            //     }
            // } else {
            //     if (this.state.vendors.length === 0) {
            //         if (!addParams.vendors) {
            //             this.setState({ error: { vendors: true }, message: { vendors: Constants.PLEASE_SELECT_VENDOR } }, () => this.props.addAgentDetails({ ...this.state }))
            //         } else {
            //             this.props.addAgent(addParams, callback, (!this.state.isVendor))
            //         }
            //     } else {
            //         this.props.addAgent(addParams, callback, (!this.state.isVendor))
            //     }
            // }
        }
    }

    goBack = () => {
        this.props.addAgentDetails(undefined)
        this.props.history.goBack()
    }
    handleSelectedAgents = (val) => {
        this.setState({ vendors: { ...this.state.vendors, selectedAgents: val } })
    }
    handleRemovedAgents = (val) => {
        if (this.props.match.params.id) {
            const results = this.state?.vendors?.selectedAgents?.filter(({ id: id1 }) => !val.some(({ id: id2 }) => id2 === id1));
            this.props.deleteAgent({ id: results[0]?.vendorAgentId }, (res) => {
                if (res.response_code === 0) {
                    toast.success("Agent removed Successfully")
                }
            })
        }
        this.setState({ vendors: { ...this.state.vendors, selectedAgents: val } })
    }
    updateAgentDetails = () => {
        const { getAgentDetails = {}, getLoginDetails = {} } = this.props
        const { addAgentDetails = {}, vendorAddAgentDetails = {} } = getAgentDetails
        const { adminTokenDetails = {}, vendorTokenDetails = {}, tokenDetails = {} } = getLoginDetails

        const callback = (response) => {
            if (response?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.addAgentDetails(undefined)
                this.props.history.push(this.state.isVendor ? RoutePath.VENDOR_AGENT_LIST : RoutePath.ADMIN_AGENT_LIST)
            } else {
                toast.error(response?.response?.data?.response_message)
            }
        }
        let updateParams = {
            id: this.props.match.params.id,
            agentType: this.state.isVendor ? vendorAddAgentDetails?.agentType : (addAgentDetails?.agentType ? addAgentDetails?.agentType : this.state.agentType),
            phone: this.state.isVendor ? vendorAddAgentDetails?.phone : addAgentDetails?.phone,
            email: this.state.isVendor ? vendorAddAgentDetails?.email : addAgentDetails?.email,
            fullName: this.state.isVendor ? vendorAddAgentDetails?.fullName : addAgentDetails?.fullName
        }
        if (updateParams.agentType == 2 && this.state.vendorId) {
            updateParams.vendorId = this.state.isVendor ? vendorTokenDetails?.userDetails?.id : addAgentDetails?.vendorId
        }
        if (updateParams.agentType == 1 && this.state?.vendors?.selectedAgents?.length > 0) {
            if (!this.state.isVendor) {
                updateParams.vendors = this.state.vendors
            }
        }
        if (!updateParams.fullName) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.PLEASE_ENTER_THE_NAME } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!isCharacterReg(updateParams.fullName)) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.NAME_REGX } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (updateParams.fullName.trim().length < 3) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.NAME_LENGTH } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!updateParams.email) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.PLEASE_ENTER_EMAIL } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!isValidEmail(updateParams.email)) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.INVALID_EMAIL_ID } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!updateParams.phone) {
            this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: Constants.PLEASE_ENTER_THE_MOBLIE_NUMBER } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (updateParams.phone.length < 10) {
            this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: Constants.MOBILE_NUMBER_MUST_BE_10_CHARCTER } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!updateParams.agentType) {
            this.setState({ error: { agentType: true }, message: { agentType: Constants.PLEASE_SELECT_AGENT_TYPE } }, () => this.props.addAgentDetails({ ...this.state }))
        } else {
            this.props.updateAgent(updateParams, callback, (!this.state.isVendor))
            // if (updateParams.agentType == 2) {
            //     if (!this.state.isVendor) {
            //         if (!updateParams.vendorId) {
            //             this.setState({ error: { vendorId: true }, message: { vendorId: Constants.PLEASE_SELECT_VENDOR } }, () => this.props.addAgentDetails({ ...this.state }))
            //         } else {
            //             this.props.updateAgent(updateParams, callback, !this.state.isVendor)
            //         }
            //     } else {
            //         this.props.updateAgent(updateParams, callback, !this.state.isVendor)
            //     }
            // } else {
            //     if (this.state?.vendors?.length === 0 || this.state?.vendors?.selectedAgents?.length === 0) {
            //         if (!updateParams?.vendors || !updateParams?.vendors?.selectedAgents) {
            //             this.setState({ error: { vendors: true }, message: { vendors: Constants.PLEASE_SELECT_VENDOR } }, () => this.props.addAgentDetails({ ...this.state }))
            //         } else {
            //             this.props.updateAgent(updateParams, callback, (!this.state.isVendor))
            //         }
            //     } else {
            //         this.props.updateAgent(updateParams, callback, (!this.state.isVendor))
            //     }
            // }
        }
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value })
        let email = e.target.value
        if (email.length === 0) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.PLEASE_ENTER_EMAIL } }, () => this.props.addAgentDetails({ ...this.state }))
        }
        else if (!isValidEmail(email)) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.INVALID_EMAIL_ID } }, () => this.props.addAgentDetails({ ...this.state }))
        }
        else {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: '' } }, () => this.props.addAgentDetails({ ...this.state }))
        }
    }
    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value })
        let password = e.target.value;
        if (!password || password.length === 0) {
            this.setState({ error: { isPassword: true }, message: { isPassword: Constants.PLEASE_ENTER_PASSWORD } }, () => this.props.addAgentDetails({ ...this.state }))
            return false;
        }
        else {
            this.setState({ error: { isPassword: true }, message: { isPassword: '' } }, () => this.props.addAgentDetails({ ...this.state }))
        }
    }
    handleMobileNumber = (e) => {
        e.target.value = e.target.value.slice(0, 10).trim()
        this.setState({ phone: e.target.value })
        let number = e.target.value
        if (!number || number.length === 0) {
            this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: Constants.PLEASE_ENTER_THE_MOBLIE_NUMBER } }, () => this.props.addAgentDetails({ ...this.state }))
            return false;
        }
        else if (number.length < 10) {
            this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: Constants.MOBILE_NUMBER_MUST_BE_10_CHARCTER } }, () => this.props.addAgentDetails({ ...this.state }))
            return false;
        }
        else {
            this.setState({ error: { isMobileNumber: false }, message: { isMobileNumber: '' } }, () => this.props.addAgentDetails({ ...this.state }))
        }

    }
    handleUserName = (e) => {
        this.setState({ fullname: e.target.value })
        let fullName = e.target.value
        if (!fullName || fullName.length === 0) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.PLEASE_ENTER_THE_NAME } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (!isCharacterReg(fullName)) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.NAME_REGX } }, () => this.props.addAgentDetails({ ...this.state }))
        } else if (fullName.trim().length < 3) {
            this.setState({ error: { isFullName: true }, message: { isFullName: Constants.NAME_LENGTH } }, () => this.props.addAgentDetails({ ...this.state }))
        } else {
            this.setState({ error: { isFullName: false }, message: { isFullName: '' } }, () => this.props.addAgentDetails({ ...this.state }))
        }
        this.setState({ fullName: e.target.value }, () => this.props.addAgentDetails({ ...this.state }))
    }
    handleDropdownChange = (e) => {
        if (e.target.value == 2) {
            this.props.vendorList()
        }
        if (!e.target.value) {
            this.setState({ error: { agentType: true }, message: { agentType: Constants.PLEASE_SELECT_AGENT_TYPE } }, () => this.props.addAgentDetails({ ...this.state }))
        } else {
            this.setState({ error: { agentType: false }, message: { agentType: "" } }, () => this.props.addAgentDetails({ ...this.state }))
        }
        this.setState({ agentType: e.target.value }, () => this.props.addAgentDetails({ ...this.state }))
    }
    handleVendorChange = (e) => {
        if (!e.target.value) {
            this.setState({ error: { vendorId: true }, message: { vendorId: Constants.PLEASE_SELECT_VENDOR } }, () => this.props.addAgentDetails({ ...this.state }))
        } else {
            this.setState({ error: { vendorId: false }, message: { vendorId: "" } }, () => this.props.addAgentDetails({ ...this.state }))
        }
        this.setState({ vendorId: e.target.value }, () => this.props.addAgentDetails({ ...this.state }))
    }
    render() {
        const { getAgentDetails = {} } = this.props
        const { isLoading, addAgentDetails = {} } = getAgentDetails
        const Agent = this.props.match.path.split('/')
        let isAgent = false
        if (Agent[2] === "agent" && Agent[1] === "admin") {
            isAgent = true
        } else {
            isAgent = false
        }
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddAgentScreen
                            isEdit={this.props.match.params.id}
                            agentDetails={isAgent ? this.state : this.state}
                            handleEmailChange={(e) => this.handleEmailChange(e)}
                            handlePasswordChange={(e) => this.handlePasswordChange(e)}
                            handleMobileNumber={(e) => this.handleMobileNumber(e)}
                            handleUserName={(e) => this.handleUserName(e)}
                            handleDropdownChange={(e) => this.handleDropdownChange(e)}
                            handleVendorChange={(e) => this.handleVendorChange(e)}
                            submit={this.props.match.params.id ? () => this.updateAgentDetails() : () => this.addAgentDetails()}
                            goBack={() => this.goBack()}
                            isAgent={isAgent}
                            getVendorList={this.props.getVendorList}
                            handleSelectedAgents={(e) => this.handleSelectedAgents(e)}
                            handleRemovedAgents={(e) => this.handleRemovedAgents(e)}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getAgentDetails: Actions.getAgentDetails(state),
        getVendorList: Actions.getCustomerCallList(state),
        getLoginDetails: Actions.getLoginDetails(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    addAgent: Actions.addAgentAction,
    updateAgent: Actions.updateAgentAction,
    getAgent: Actions.getAgentAction,
    addAgentDetails: Actions.addAgentDetails,
    vendorList: Actions.vendorActiveListAction,
    deleteAgent: Actions.vendorAgentDeleteAction
})(AddAgent));