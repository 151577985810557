import { Component } from "react";
import { withRouter } from "react-router-dom";
import { SOCKET_RTC_URL } from "../utils/Constants";

class ScreenShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            isChatOpened: false,
            chat: [],
            message: '',
            isMute: false,
            isVideoMute: false,
            unreadMessageCount: 0,
            isZoom: true,
            videoStream: [],
        }
    }

    componentDidMount() {
        this.loadVideoConferencing();
    }

    shareVideo = () => {
        const { roomId } = this.props.match.params;
        if (!this.connection) {
            return false
        }
        this.connection.open(roomId);
    }

    joinVideConferencing = () => {
        const { roomId, share, view } = this.props;
        if (share) {
            if (!this.connection) {
                return false
            }
            this.connection.open(roomId);
        }
        if (view) {
            if (roomId && roomId.length) {
                // auto-join-room
                this.connection.checkPresence(roomId, function (isRoomExist) {
                    if (isRoomExist) {
                        this.connection.join(roomId);
                        return;
                    }
                    setTimeout(() => {
                        this.joinVideConferencing()
                    }, 5000);
                }.bind(this));
            }
        }
    }

    loadVideoConferencing = () => {
        const { share, roomId } = this.props;
        this.connection = new global.RTCMultiConnection();

        // by default, socket.io server is assumed to be deployed on your own URL
        if (window.location.hostname === 'localhost') {
            this.connection.socketURL = `http://localhost:9001/`;
        }
        else {
            this.connection.socketURL = SOCKET_RTC_URL;
        }

        // comment-out below line if you do not have your own socket.io server
        // connection.socketURL = 'https://muazkhan.com:9001/';

        this.connection.socketMessageEvent = 'screen-sharing-demo';

        this.connection.session = {
            screen: true,
            oneway: true
        };

        this.connection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false
        };

        // https://www.rtcmulticonnection.org/docs/iceServers/
        // use your own TURN-server here!
        // this.connection.iceServers = [{
        //     'urls': [
        //         'stun:stun.l.google.com:19302',
        //         'stun:stun1.l.google.com:19302',
        //         'stun:stun2.l.google.com:19302',
        //         'stun:stun.l.google.com:19302?transport=udp',
        //     ]
        // }];

        this.connection.videosContainer = document.getElementById('videos-container');
        this.connection.onstream = (event) => {
            var existing = document.getElementById(event.streamid);
            if (existing && existing.parentNode) {
                existing.parentNode.removeChild(existing);
            }
            if (share) {
                this.props.onScreenShared(roomId)
            }

            event.mediaElement.removeAttribute('src');
            event.mediaElement.removeAttribute('srcObject');
            event.mediaElement.muted = true;
            event.mediaElement.volume = 0;

            var video = document.createElement('video');

            try {
                video.setAttributeNode(document.createAttribute('autoplay'));
                video.setAttributeNode(document.createAttribute('playsinline'));
            } catch (e) {
                video.setAttribute('autoplay', true);
                video.setAttribute('playsinline', true);
            }
            const isLocal = event.type === 'local';
            if (event.type === 'local') {
                video.volume = 0;
                try {
                    video.setAttributeNode(document.createAttribute('muted'));
                } catch (e) {
                    video.setAttribute('muted', true);
                }
            }
            video.srcObject = event.stream;

            // var width = innerWidth - 80;
            var mediaElement = global.getHTMLMediaElement(video, {
                // title: event.userid,
                buttons: [],
                // width: width,
                showOnMouseEnter: false
            }, isLocal, false, true);

            this.connection.videosContainer.appendChild(mediaElement);

            setTimeout(function () {
                mediaElement.media.play();
            }, 5000);

            mediaElement.id = event.streamid;
        };

        this.connection.onstreamended = (event) => {
            // alert('screensahre stoped')

            var mediaElement = document.getElementById(event.streamid);
            if (mediaElement) {
                mediaElement.parentNode.removeChild(mediaElement);

                if (event.userid === this.connection.sessionid && !this.connection.isInitiator) {
                    alert('Broadcast is ended. We will reload this page to clear the cache.');
                    window.location.reload();
                }
            }
            this.props.onStopScreenShare();
        };

        this.connection.onMediaError =  (e)=> {
            this.props.onStopScreenShare();
            if (e.message === 'Concurrent mic process limit.') {
                if (global.DetectRTC.audioInputDevices.length <= 1) {
                    alert('Please select external microphone. Check github issue number 483.');
                    return;
                }

                var secondaryMic = global.DetectRTC.audioInputDevices[1].deviceId;
                this.connection.mediaConstraints.audio = {
                    deviceId: secondaryMic
                };

                this.connection.join(this.connection.sessionid);
            }
        };


        // ......................................................
        // ......................Handling Room-ID................
        // ......................................................

        (function () {
            var params = {},
                r = /([^&=]+)=?([^&]*)/g;
            function d(s) {
                return decodeURIComponent(s.replace(/\+/g, ' '));
            }
            var match, search = window.location.search;
            while (match = r.exec(search.substring(1)))
                params[d(match[1])] = d(match[2]);
            window.params = params;
        })();

        // detect 2G
        if (navigator.connection &&
            navigator.connection.type === 'cellular' &&
            navigator.connection.downlinkMax <= 0.115) {
            alert('2G is not supported. Please use a better internet service.');
        }
        this.joinVideConferencing()
    }
    render() {
        const { share } = this.props;
        return (
            <>
                <div id="videos-screen-container" className={`${share ? 'v-hidden' : ''}`}>

                </div>
            </>
        );
    }
}

export default withRouter(ScreenShare);