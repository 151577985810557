import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import ViewAgentScreen from '../component/ViewAgentScreen';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';

class ViewAgent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: false,
        }
    }
    componentDidMount() {
        const { agentSelectedId, isAgent } = this.props;
        let param = {
            id: agentSelectedId
        }
        const callback = (response) => {
            this.setState({ data: response?.response, isLoading: false })
        }
        this.setState({ isLoading: true })
        if (!isAgent) {
            this.props.adminAgentDetails(param, callback, isAgent)
        } else {
            this.props.adminAgentDetails(param, callback, isAgent)
        }
    }
    setLoading = (state) => {
        this.setState({ isLoading: state })
    }
    render() {
        const { data, isLoading } = this.state;
        const { agentSelectedId, isAgent } = this.props
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div>
                            <ViewAgentScreen
                                agentData={data}
                                isLoading={this.setLoading}
                                agentSelectedId={agentSelectedId}
                                isAgent={isAgent}
                            />
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        agentSelectedId: state?.AGENR_DETAILS?.agentSelectedId?.id,
        isAgent: state?.AGENR_DETAILS?.agentSelectedId?.agent,
        agentState: state?.AGENR_DETAILS
    }
};
export default withRouter(connect(mapStateToProps, {
    adminAgentDetails: Actions.getAdminAgentDetails
})(ViewAgent));