import { doAction, doResponseAction } from ".";

const CUSTOMER_LIST = "CUSTOMER_LIST";
const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
const CUSTOMER_LIST_FAILURE = "CUSTOMER_LIST_FAILURE";

export {
  CUSTOMER_LIST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAILURE
};
export const getcutomerList = store => store['CUSTOMER_LIST']
export const cutomerListAction = (params, callback) => doAction(CUSTOMER_LIST, params, callback);
export const customerListSuccessAction = (data) => doResponseAction(CUSTOMER_LIST_SUCCESS, data);
export const customerListFailureAction = (data) => doResponseAction(CUSTOMER_LIST_FAILURE, data);
