import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constants from '../utils/Constants';
import RoutePath from '../utils/routes/RoutePath';
import adminLogo from '../assets/img/Live_Agent_Logo.svg'
class ResponsiveNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const route = this.props.route
        return (
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-width">
                    <div className="modal-content content-part">
                        <div className="modal-header heading-modal">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="logo-content">
                                <img src={adminLogo} className="img-fluid main-logo" alt="logo" />
                            </div>
                            <ul className="nav flex-column responsive_nav">
                                <Link to={route[1] === "admin" ? RoutePath.ADMIN_DASHBOARD_SCREEN : RoutePath.VENDOR_DASHBOARD_SCREEN} className={`nav-item link-item  ${route[2] === "dashboard" ? 'link-type' : ''}`}>
                                    <p className="nav-link link-content ps-5 py-3" data-bs-dismiss="modal"><i className="fa-solid fa-gauge-high dashboard-icon me-3"></i>{Constants.DASHBOARD}</p>
                                </Link>
                                {route[1] === "admin" && <Link to={RoutePath.ADMIN_VENDOR_LIST} className={`nav-item link-item  ${route[2] === "vendor" ? 'link-type' : ''}`}>
                                    <p className="nav-link link-content ps-5 py-3" data-bs-dismiss="modal"><i className="fa-solid fa-user-group vendor-icon me-3"></i>{Constants.VENDOR}</p>
                                </Link>}
                                <Link to={route[1] === "admin" ? RoutePath.ADMIN_AGENT_LIST : RoutePath.VENDOR_AGENT_LIST} className={`nav-item link-item ${route[2] === "agent" ? 'link-type' : ''}`}>
                                    <p className="nav-link link-content ps-5 py-3" data-bs-dismiss="modal"><i className="fa-solid fa-users agent-icon me-3"></i>{Constants.AGENT}</p>
                                </Link>
                                {route[1] === "admin" && <Link to={RoutePath.SUBSCRIPTION_LIST} className={`nav-item link-item ${route[2] === "subscription" ? 'link-type' : ''}`}>
                                    <p className="nav-link link-content ps-5 py-3" data-bs-dismiss="modal"><i className="fa-solid fa-bell bell-icon me-3"></i>{Constants.SUBSCRIPTION}</p>
                                </Link>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResponsiveNavBar;