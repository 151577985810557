import Actions from "../actions";

const initialState = {
  isLoading: false,
  customerList: undefined,
  error: false,
};

export const CustomerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CUSTOMER_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.CUSTOMER_LIST_SUCCESS: {
      localStorage.setItem("vendorId",action?.data?.response?.encryptId)
      return {
        ...state,
        isLoading: false,
        customerList: action.data,
        error: false,
      };
    }
    case Actions.CUSTOMER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state
    }
  }
};
export const customerListLoader = (state) => state?.CUSTOMER_LIST;
