import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Actions from '../redux/actions/index';
import { isValidEmail, isValidPassword } from '../utils/validation';
import RoutePath from '../utils/routes/RoutePath';
import AppLoader from '../utils/AppLoader';
import Constants from '../utils/Constants';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import loginLogo from '../../src/assets/img/Live_Agent_Logo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: {},
            message: {},
            isAdmin: false,
            isVendor: false,
            validEmail: true,
            validPassword: true,
            showPassword: false,
        }
    }


    componentDidMount() {
        if (this.props.location.pathname === RoutePath.ADMIN_LOGIN_SCREEN_PATH) {
            this.setState({ isAdmin: true, isVendor: false })
            const { getLoginDetails } = this.props
            if ((getLoginDetails?.adminTokenDetails?.access_token ?? false) && localStorage.length > 0) {
                this.props.history.push('/admin/dashboard')
            }
        } else if (this.props.location.pathname === RoutePath.VENDOR_LOGIN_SCREEN_PATH) {
            this.setState({ isVendor: true, isAdmin: false })
            const { getLoginDetails } = this.props
            if ((getLoginDetails?.vendorTokenDetails?.access_token ?? false) && localStorage.length > 0) {
                this.props.history.push('/vendor/dashboard')
            }
        } else if (this.props.location.pathname === RoutePath.LOGIN_SCREEN_PATH && localStorage.length > 0) {
            const { getLoginDetails } = this.props
            if ((getLoginDetails?.tokenDetails?.access_token ?? false) && localStorage.length > 0) {
                this.props.history.push('/store')
            }
        }
    }

    submitHandler = () => {
        this.setState({ isLoading: true })
        const { email, password, isAdmin, isVendor, error, message } = this.state;
        if (!email) {
            this.setState({ isLoading: false, error: { isEmailId: true }, message: { isEmailId: Constants.PLEASE_ENTER_EMAIL } });
        } else if (!isValidEmail(email)) {
            this.setState({ isLoading: false, error: { isEmailId: true }, message: { isEmailId: Constants.INVALID_EMAIL_ID } });
        } else {
            this.setState({ validEmail: false })
            const params = {
                grant_type: "password",
                username: email,
                password: password,
                scope: isAdmin ? "super_admin" : isVendor ? "vendor_admin" : "agent",
                roleId: isAdmin ? 1 : isVendor ? 2 : 3
            }
            const logincallback = (response) => {
                if (response.response_code === 0) {
                    this.setState({ isLoading: false })
                    switch (response?.response?.user?.roleId) {
                        case 1:
                            this.props.history.push(RoutePath.ADMIN_DASHBOARD_SCREEN)
                            break;
                        case 2:
                            // if (response?.response?.userDetails?.Vendor?.paymentStatus !== "paid") {
                            //     this.props.history.push(RoutePath.ACCESS_DENIED) 
                            // } else {
                            this.props.history.push(RoutePath.VENDOR_DASHBOARD_SCREEN)
                            // }
                            break;
                        case 3:
                            // if (response?.response?.userDetails?.VendorAgent?.Vendor?.paymentStatus !== "paid") {
                            //     this.props.history.push(RoutePath.ACCESS_DENIED) 
                            // } else {
                            localStorage.setItem('companyName', response?.response?.userDetails?.VendorAgent?.Vendor?.companyName);
                            this.props.history.push(RoutePath.STOREMANAGER_SCREEN_PATH)
                            // }
                            break;
                        default:
                            break;
                    }
                } else {
                    toast.error(response?.response_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            }
            this.props.login(params, logincallback, isAdmin, isVendor)
        }
    }
    forgetPassword() {
        const params = {
            grant_type: "client_credentials",
            scope: "public"
        }
        const forgetPasswordCallback = (response) => {
            if (response.response_message === "success") {
                this.props.history.push(RoutePath.FORGET_PASSWORD)
            }
        }
        this.props.forgetpassword(params, forgetPasswordCallback)

    }
    handleEmail = (event) => {
        this.setState({ email: event.target.value })
        let email = event.target.value
        if (email.length === 0) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.PLEASE_ENTER_EMAIL } })
            this.setState({ validEmail: true })
            return false;
        }
        else if (!isValidEmail(email)) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.INVALID_EMAIL_ID } })
            this.setState({ validEmail: true })
            return false;
        }
        else {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: '' } })
            this.setState({ validEmail: false })
        }
    }
    handlePassword = (event) => {
        this.setState({ password: event.target.value })
        let password = event.target.value;
        if (!password || password.length === 0) {
            this.setState({ error: { isPassword: true }, message: { isPassword: Constants.PLEASE_ENTER_PASSWORD } })
            this.setState({ validPassword: true })
            return false;
        }
        // else if (!isValidPassword(password)) {
        //     this.setState({ error: { isPassword: true }, message: { isPassword: Constants.PASSWORD_REG } })
        //     this.setState({ validPassword: true })
        //     return false;
        // }
        else {
            this.setState({ error: { isPassword: false }, message: { isPassword: '' } })
            this.setState({ validPassword: false })
        }
    }
    render() {
        const { email, password, error, message, validEmail, validPassword, showPassword } = this.state;
        const { isLoading } = this.props.getLoginDetails
        const Agent = this.props.match.path.split('/')
        return (
            <div className="proglint-meet">
                <div className="container">
                    <div className="row ">
                        <div className="d-flex justify-content-center  justify-content-md-end justify-content-lg-end mt-4">
                            {/* <img src={loginLogo} className="logo-1" alt="logo" width="5%" /> */}
                            {/* <img src={require('../assets/img/proglint Transparent.png')} className="logo-2" alt="logo" width="30%" /> */}
                            {/* <img src={require('../assets/img/proglint Transparent.png')} className="logo-3" alt="logo" width="50%" /> */}
                        </div>
                    </div>
                    <div className="row login-card-content">
                        <div className='d-flex align-items-center'>
                            <div className="d-none d-md-flex col-md-6 col-lg-6 login-left justify-content-center">
                                {
                                    Agent[1] === "vendor" ? <img src={require('../assets/img/vendor_login.png')} alt="login" width="60%" /> : Agent[1] === "admin" ? <img src={require('../assets/img/customer_care.png')} alt="login" width="40%" /> :
                                        <img src={require('../assets/img/customer.png')} alt="login" width="40%" />
                                }
                            </div>
                            <div className="col-md-6 col-lg-6 login-right">
                                <div className="px-5">
                                    <img src={loginLogo} className="logo-1 d-block mx-auto mb-2" alt="logo" width="15%" />
                                    <h2 className="login-head">{Constants.LOGIN}</h2>
                                    <div className="col-12 login_inputBox mt-4">
                                        <input autoComplete="off" type="text" className="form-control input-box " placeholder={Constants.EMAIL_ID}
                                            aria-label="User name" value={email} onChange={(e) => this.handleEmail(e)} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    this.submitHandler()
                                                }
                                            }} />
                                    </div>
                                    <div>
                                        {error.isEmailId && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isEmailId}</h5>}
                                    </div>
                                    <div className="col-12 mt-4 input-group flex-nowrap mb-4 login_inputBox">
                                        <input autoComplete="off" type={showPassword ? 'text' : 'password'} className="form-control input-box" placeholder={Constants.PASSWORD}
                                            aria-label="Password" aria-describedby="view-pass" value={password} onChange={(e) => this.handlePassword(e)} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    this.submitHandler()
                                                }
                                            }}></input>
                                        {/* <span className='input-group-text'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                    </svg></span> */}
                                        <button className="btn"
                                            onClick={() => this.setState({ showPassword: !showPassword })}
                                            type="button"
                                            id="view-pass">
                                            <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={showPassword ? faEye : faEyeSlash} />
                                        </button>
                                        {/* <><button className="btn"
                                        onClick={() => this.setState({ showPassword: !showPassword })}
                                        type="button"
                                        id="view-pass">
                                        <FontAwesomeIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={showPassword ? faEye : faEyeSlash} />
                                    </button></> */}
                                    </div>
                                    <div>
                                        {error.isPassword && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isPassword}</h5>}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'end' }} className="pt-1">
                                        <p className='m-0 forgetPassword' onClick={() => this.forgetPassword()}>Forgot password</p>
                                    </div>
                                    <div className="col-12 text-center input-button">
                                        <button className="btn login-btn" type="button" disabled={(validEmail || validPassword)}
                                            onClick={() => this.submitHandler()}>{Constants.LOGIN}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getLoginDetails: Actions.getLoginDetails(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    login: Actions.loginAction,
    forgetpassword: Actions.forgetPassword
})(Login))