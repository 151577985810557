import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import Constants from '../utils/Constants';
import { isValidPassword } from '../utils/validation';


class SetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.otpRef1 = React.createRef();
        this.otpRef2 = React.createRef();
        this.otpRef3 = React.createRef();
        this.otpRef4 = React.createRef();
        this.state = {
            email: '',
            validEmail: true,
            validNewPassword: true,
            validConfirmPassword: true,
            newPassword: "",
            showPassword: false,
            confirmPassword: '',
            showConfirmPassword: false,
            error: {},
            vendorId: null,
            isLoading: false
        }
    }
    componentDidMount() {
        this.setState({ vendorId: this.props.match.params.vendorId })
    }
    handleChangeNewPassword = (e) => {
        this.setState({ newPassword: e.target.value })
        let newPassword = e.target.value;
        if (!newPassword || newPassword.length === 0) {
            this.setState({ error: { isNewPassword: true }, message: { isNewPassword: Constants.PLEASE_ENTER_PASSWORD } })
            this.setState({ validNewPassword: true })
            return false;
        }
        else if (!isValidPassword(newPassword)) {
            this.setState({ error: { isNewPassword: true }, message: { isNewPassword: Constants.PASSWORD_REG } })
            this.setState({ validNewPassword: true })
            return false;
        }
        else {
            this.setState({ error: { isNewPassword: false }, message: { isNewPassword: '' } })
            this.setState({ validNewPassword: false })
        }
    }

    handleChangeConfirmPassword = (e) => {
        this.setState({ confirmPassword: e.target.value })
        let confirmPassword = e.target.value;
        if (!confirmPassword || confirmPassword.length === 0) {
            this.setState({ error: { isConfirmPassword: true }, message: { isConfirmPassword: Constants.PLEASE_ENTER_PASSWORD } })
            this.setState({ validConfirmPassword: true })
            return false;
        }
        else if (!isValidPassword(confirmPassword)) {
            this.setState({ error: { isConfirmPassword: true }, message: { isConfirmPassword: Constants.PASSWORD_REG } })
            this.setState({ validConfirmPassword: true })
            return false;
        }
        else {
            this.setState({ error: { isConfirmPassword: false }, message: { isConfirmPassword: '' } })
            this.setState({ validConfirmPassword: false })
        }
    }

    submitVerifyPassword = () => {
        const { newPassword, confirmPassword } = this.state;
        const { setPassword } = this.props;
        this.setState({ isLoading: true })
        if (confirmPassword !== newPassword) {
            this.setState({ isLoading: false })
            toast.warn('Password does not match')
            return false
        }

        const params = {
            encryptId: this.state.vendorId,
            password: confirmPassword
        }

        const callback = response => {
            this.setState({ isLoading: false })
            if (response) {
                toast.success("Password Update Successfully")
                if (response?.response?.roleId == 2) {
                    return this.props.history.push('/vendor')
                }
                if (response?.response?.roleId == 1) {
                    return this.props.history.push('/admin')
                }
            } else {
                return false;
            }
        }
        setPassword(params, callback)
    }
    render() {
        const { newPassword, showPassword, confirmPassword, showConfirmPassword, error, message, validNewPassword, validConfirmPassword, isLoading } = this.state;
        const { setPassword = {} } = this.props;
        return (
            <div>
                {isLoading && <AppLoader />}
                <div className="container">
                    <div className="email_otp">
                        <div className="card otp_card">
                            <div className="row">
                                <h3 className="text-center my-2">Please Set Your New Password</h3>
                                <div className="mt-3">
                                    <p className="text-bold">New Password</p>
                                    <div className="mt-4 input-group flex-nowrap mb-4">
                                        <input autoComplete="off" type={showPassword ? 'text' : 'password'} name='password' className="form-control w-75" aria-label="Password" aria-describedby="view-pass" placeholder="Please Enter the New Password" value={newPassword} onChange={(e) => this.handleChangeNewPassword(e)} />
                                        <button className="btn"
                                            onClick={() => this.setState({ showPassword: !showPassword })}
                                            type="button"
                                            id="view-pass">
                                            <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={showPassword ? faEye : faEyeSlash} />
                                        </button>
                                    </div>
                                    {error.isNewPassword && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isNewPassword}</h5>}
                                </div>
                                <div className='mt-4'>
                                    <p className="text-bold">Confirm Password</p>
                                    <div className="mt-4 input-group flex-nowrap mb-4">
                                        <input autoComplete="off" type={showConfirmPassword ? 'text' : 'password'} name='password' className="form-control w-75" aria-label="Password" aria-describedby="view-pass" placeholder="Please Enter the confirm password" value={confirmPassword} onChange={(e) => this.handleChangeConfirmPassword(e)} />
                                        <button className="btn"
                                            onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                                            type="button"
                                            id="view-pass">
                                            <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={showConfirmPassword ? faEye : faEyeSlash} />
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {error.isConfirmPassword && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isConfirmPassword}</h5>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5 mb-3"> <button type="submit" disabled={validNewPassword || validConfirmPassword} className="btn otp_btn" onClick={() => this.submitVerifyPassword()}>Submit</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
export default withRouter(connect(mapStateToProps, {
    setPassword: Actions.setNewPassword
})(SetPassword))