import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import Constants, { LIMIT } from "../utils/Constants";
import NoDataFound from "./NoDataFound";
import PaginationComponenent from '../component/Pagination';
import { connect } from "react-redux";
import Actions from "../redux/actions";
import { msToHMSShort } from "../utils/validation";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

class ViewAgentScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: '',
            list: []
        }
    }
    componentDidMount() {
        this.getCallHistory()
    }
    getCallHistory(isUpdate) {
        const { isLoading, agentSelectedId, isAgent } = this.props
        let param = {
            id: agentSelectedId,
            limit: LIMIT,
            pageNumber: isUpdate ? this.state.pageNumber - 1 : 0
        }
        const callback = (response) => {
            isLoading(false)
            this.setState({ list: response?.response?.historys, totalItems: response?.response?.totalItems, isLoading: false })
        }
        isLoading(true)
        if (!isAgent) {
            this.props.vendorAgentCall(param, callback)
        } else {
            this.props.adminAgentCall(param, callback)
        }
    }
    onChangePagination = (pageNumber) => {
        const { agentSelectedId, isLoading, isAgent } = this.props
        let params = {
            id: agentSelectedId,
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        const callBack = (response) => {
            isLoading(false)
            this.setState({ list: response?.response?.historys, totalItems: response?.response?.totalItems, isLoading: false })
        }
        isLoading(true)
        if (!isAgent) {
            this.props.vendorAgentCall(params, callBack)
        } else {
            this.props.adminAgentCall(params, callBack)
        }
        this.setState({ pageNumber: pageNumber })
    }
    render() {
        const { pageNumber, totalItems, list } = this.state;
        const { agentData } = this.props
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.NAME}
                                </div>
                                <div className='col-7'>
                                    <p>{agentData && agentData.fullName ? agentData.fullName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.EMAIL}
                                </div>
                                <div className='col-7'>
                                    <p>{agentData && agentData.email ? agentData.email : '-'}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.ACTIVE}
                                </div>
                                <div className='col-7'>
                                    <p>{agentData && agentData.isActive === 1 ? Constants.ACTIVE : Constants.INACTIVE}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.MOBILE_NUMBER}
                                </div>
                                <div className='col-7'>
                                    <p>{agentData && agentData?.phone ? agentData?.phone : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.DATE}
                                </div>
                                <div className='col-7'>
                                    <p>
                                        {agentData && agentData?.createdAt ? moment(agentData?.createdAt).format("DD/MM/YYYY hh:mm:ss:A") : "-"}</p>
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-5 text-bold'>
                        Agent call history :
                    </div>
                    <div className="admin_table agent-table">
                        <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                            <table className="dashboard_table">
                                <thead>
                                    <tr>
                                        <th className="pin">{Constants.ID}</th>
                                        <th>Customer Name</th>
                                        <th>Start Time</th>
                                        <th>Duration</th>
                                        <th>End Time</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(list?.length > 0 ?
                                        list?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="pin">{((pageNumber - 1) * LIMIT) + (index + 1)}</td>
                                                    <td>{item?.User?.fullName ? item?.User?.fullName : "-"}</td>
                                                    <td>{item?.createdAt ? moment(item?.createdAt).format("DD/MM/YYYY hh:mm:ss:A") : "-"}</td>
                                                    <td>{item?.callDuration ? msToHMSShort(item?.callDuration) : "-"}</td>
                                                    <td>{item?.updatedAt ? moment(item?.updatedAt).format("DD/MM/YYYY hh:mm:ss:A") : "-"}</td>
                                                    <td className={`feedback_${index}`}>
                                                        {item?.feedback ? item?.feedback?.substring(0, 10).concat('...') : "-"}
                                                        {item?.feedback && <Tooltip anchorSelect={`.feedback_${index}`} place="bottom">
                                                            {item?.feedback}
                                                        </Tooltip>}
                                                    </td>
                                                </tr>
                                            );
                                        }) : <NoDataFound />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='agentpage-content'>
                        {totalItems > (LIMIT - 1) &&
                            <PaginationComponenent currentPage={pageNumber} countValue={totalItems} onChangeHandle={this.onChangePagination} />}
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    adminAgentCall: Actions.adminAgentCallHistoryAction,
    vendorAgentCall: Actions.vendorAgentCallHistoryAction
})(ViewAgentScreen));